/*--------------------------------

NF_iconset Web Font
Generated using nucleoapp.com

-------------------------------- */

@font-face {
    font-family: "NF_iconset";
    src: url("../fonts/NF_iconset.eot");
    src: url("../fonts/NF_iconset.eot") format("embedded-opentype"), url("../fonts/NF_iconset.woff2") format("woff2"),
        url("../fonts/NF_iconset.woff") format("woff"), url("../fonts/NF_iconset.ttf") format("truetype"),
        url("../fonts/NF_iconset.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

/*------------------------
      base class definition
  -------------------------*/
.icon {
    display: inline-block;
    font: normal normal normal 1em/1 "NF_iconset";
    speak: none;
    text-transform: none;
    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*------------------------
    change icon size
  -------------------------*/
/* relative units */
.icon-sm {
    font-size: 0.8em;
}

.icon-lg {
    font-size: 1.2em;
}

/* absolute units */
.icon-16 {
    font-size: 16px;
}

.icon-32 {
    font-size: 32px;
}

/*----------------------------------
    add a square/circle background
  -----------------------------------*/
.icon-bg-square,
.icon-bg-circle {
    padding: 0.35em;
    background-color: #eee;
}

.icon-bg-circle {
    border-radius: 50%;
}

/*------------------------------------
    use icons as list item markers
  -------------------------------------*/
.icon-ul {
    padding-left: 0;
    list-style-type: none;
}

.icon-ul>li {
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
}

.icon-ul>li>.icon {
    margin-right: 0.4em;
    line-height: inherit;
}

/*------------------------
    spinning icons
  -------------------------*/
.icon-is-spinning {
    -webkit-animation: icon-spin 2s infinite linear;
    -moz-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes icon-spin {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*------------------------
    rotated/flipped icons
  -------------------------*/
.icon-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.icon-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.icon-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

.icon-flip-y {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
    -webkit-transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.icon-flip-x {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: scale(1, -1);
    -moz-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    transform: scale(1, -1);
}

/*------------------------
      icons
  -------------------------*/

.icon-clock::before {
    content: "\ea02";
}

.icon-grades::before {
    content: "\ea03";
}

.icon-students::before {
    content: "\ea04";
}

.icon-error::before {
    content: "\ea05";
}

.icon-success::before {
    content: "\ea06";
}

.icon-closeCircle::before {
    content: "\ea07";
}

.icon-smallAdd::before {
    content: "\ea08";
}

.icon-messages::before {
    content: "\ea09";
}

.icon-tag::before {
    content: "\ea0a";
}

.icon-sticker::before {
    content: "\ea0b";
}

.icon-folder::before {
    content: "\ea0c";
}

.icon-update::before {
    content: "\ea0d";
}

.icon-camera::before {
    content: "\ea0e";
}

.icon-video::before {
    content: "\ea0f";
}

.icon-audio::before {
    content: "\ea10";
}

.icon-delete::before {
    content: "\ea11";
}

.icon-test::before {
    content: "\ea12";
}

.icon-stop::before {
    content: "\ea13";
}

.icon-feed::before {
    content: "\ea14";
}

.icon-heart::before {
    content: "\ea15";
}

.icon-family::before {
    content: "\ea17";
}

.icon-settings::before {
    content: "\ea18";
}

.icon-share::before {
    content: "\ea19";
}

.icon-archive::before {
    content: "\ea1a";
}

.icon-profile::before {
    content: "\ea1b";
}

.icon-sun::before {
    content: "\ea1c";
}

.icon-logout::before {
    content: "\ea1d";
}

.icon-cameraSwap::before {
    content: "\ea1e";
}

.icon-send::before {
    content: "\ea1f";
}

.icon-facebook::before {
    content: "\ea20";
}

.icon-computer::before {
    content: "\ea21";
}

.icon-text::before {
    content: "\ea22";
}

.icon-microsoft::before {
    content: "\ea23";
}

.icon-contextual::before {
    content: "\ea24";
}

.icon-accountRemoval::before {
    content: "\ea25";
}

.icon-brokenImage::before {
    content: "\ea26";
}

.icon-twitter::before {
    content: "\ea27";
}

.icon-instagram::before {
    content: "\ea28";
}

.icon-star::before {
    content: "\ea29";
}

.icon-pin::before {
    content: "\ea2a";
}

.icon-calendar::before {
    content: "\ea2b";
}

.icon-view::before {
    content: "\ea2c";
}

.icon-edit::before {
    content: "\ea2d";
}

.icon-question::before {
    content: "\ea2f";
}

.icon-log::before {
    content: "\ea30";
}

.icon-image::before {
    content: "\ea31";
}

.icon-close::before {
    content: "\ea32";
}

.icon-checkmark::before {
    content: "\ea33";
}

.icon-down::before {
    content: "\ea34";
}

.icon-up::before {
    content: "\ea35";
}

.icon-fullscreen::before {
    content: "\ea36";
}

.icon-audioOn::before {
    content: "\ea37";
}

.icon-mute::before {
    content: "\ea38";
}

.icon-info::before {
    content: "\ea39";
}

.icon-play::before {
    content: "\ea3a";
}

.icon-google::before {
    content: "\ea3b";
}

.icon-qrCode::before {
    content: "\ea3c";
}

.icon-schoolLogin::before {
    content: "\ea3d";
}

.icon-notification::before {
    content: "\ea3e";
}

.icon-trial::before {
    content: "\ea3f";
}

.icon-filter::before {
    content: "\ea40";
}

.icon-upload::before {
    content: "\ea41";
}

.icon-download::before {
    content: "\ea42";
}

.icon-refresh::before {
    content: "\ea43";
}

.icon-search::before {
    content: "\ea44";
}

.icon-audioOff::before {
    content: "\ea45";
}

.icon-add::before {
    content: "\ea46";
}

.icon-right::before {
    content: "\ea47";
}

.icon-move::before {
    content: "\ea48";
}

.icon-left::before {
    content: "\ea49";
}

.icon-pause::before {
    content: "\ea4a";
}

.icon-oneway::before {
    content: "\ea4b";
}

.icon-twoway::before {
    content: "\ea4c";
}

.icon-link::before {
    content: "\ea4d";
}

.icon-file::before {
    content: "\ea4e";
}

.icon-hidden::before {
    content: "\ea4f";
}

.icon-spaces::before {
    content: "\ea50";
}

.icon-react-1::before {
    content: "\ea53";
}

.icon-lock::before {
    content: "\ea54";
}

.icon-icon_react_2::before {
    content: "\ea55";
}

.icon-apple::before {
    content: "\ea56";
}

.icon-publish::before {
    content: "\ea57";
}

.icon-learningGoal::before {
    content: "\ea58";
}

.icon-notificationRead::before {
    content: "\ea59";
}

.icon-class::before {
    content: "\ea5a";
}

.icon-sort::before {
    content: "\ea5b";
}

.icon-sideClose::before {
    content: "\ea5c";
}

.icon-sideOpen::before {
    content: "\ea5d";
}

.icon-menu::before {
    content: "\ea5e";
}

.icon-min::before {
    content: "\ea5f";
}

.icon-teacher::before {
    content: "\ea60";
}

.icon-district::before {
    content: "\ea61";
}

.icon-reportingSpace::before {
    content: "\ea62";
}

.icon-ideas::before {
    content: "\ea63";
}

.icon-classSpace::before {
    content: "\ea64";
}

.icon-groupSpace::before {
    content: "\ea65";
}

.icon-individualSpace::before {
    content: "\ea66";
}

.icon-rteItalics::before {
    content: "\ea67";
}

.icon-rteUnderline::before {
    content: "\ea68";
}

.icon-rteBold::before {
    content: "\ea6b";
}

.icon-rteHighlight::before {
    content: "\ea6e";
}

.icon-rteColour::before {
    content: "\ea6f";
}

.icon-rteNumberedList::before {
    content: "\ea70";
}

.icon-indentLeft::before {
    content: "\ea72";
}

.icon-rteBulletedList::before {
    content: "\ea73";
}

.icon-indentRight::before {
    content: "\ea74";
}

.icon-noColour::before {
    content: "\ea75";
}

.icon-proficiencyReport::before {
    content: "\ea76";
}

.icon-unlock::before {
    content: "\ea77";
}

@font-face {
    font-family: "Avenir Next LT Pro";
    src: url("../fonts/AvenirNextLTPro-Demi.woff2") format("woff2"),
        url("../fonts/AvenirNextLTPro-Demi.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Museo Sans 300";
    src: url("../fonts/MuseoSans-300.woff2") format("woff2"), url("../fonts/MuseoSans-300.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Museo Sans 500";
    src: url("../fonts/MuseoSans-500.woff2") format("woff2"), url("../fonts/MuseoSans-500.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Museo Sans 700";
    src: url("../fonts/MuseoSans-700.woff2") format("woff2"), url("../fonts/MuseoSans-700.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Museo Sans 900";
    src: url("../fonts/MuseoSans-900.woff2") format("woff2"), url("../fonts/MuseoSans-900.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

:root {
    --Avenir: "Avenir Next LT Pro";
    --Museo900: "Museo Sans 900";
    --Museo700: "Museo Sans 700";
    --Museo500: "Museo Sans 500";
    --Museo300: "Museo Sans 300";
}

body {
    font-family: "Museo Sans 300" !important;
    background-color: #f7f8fa !important;
}

.portalOpenClass {
    overflow: hidden;
}

b,
strong {
    font-family: var(--Museo700);
}

img {
    max-width: 100%;
}

.loading-main,
.error-main {
    background-color: #fff;
    min-height: 100vh;
}

.error-main {
    background-color: #f7f8fa;
    padding: 0 16px;
}

.error-main h1 {
    font-size: 40px;
    color: #252a33;
    line-height: 1.4;
    font-family: var(--Museo900);
    margin: 16px 0;
}

.home-btn a {
    color: #2962ff;
    font-size: 20px;
    font-family: var(--Museo500);
    line-height: 1.6;
    padding: 11.2px 82px 12.8px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(37, 42, 51, 0.1), 0 1px 3px 0 rgba(37, 42, 51, 0.22);
    text-decoration: none;
}

.home-btn a i {
    margin-right: 8px;
}

.error-main p {
    font-size: 20px;
    color: #65738b;
    line-height: 1.6;
    margin-bottom: 40px;
}

.loading-main h1 {
    font-family: var(--Avenir);
    color: #252a33;
    margin: 28px 0 0;
    font-size: 24px;
}

.template-name-head {
    text-align: center;
    border-bottom: 1px solid #d9dfea;
    padding: 18px 0;
    background: #fff;
}

.template-head-main {
    position: sticky;
    top: 0;
    z-index: 99;
}

.template-name-head h4 {
    margin-bottom: 0;
    font-size: 16px;
    color: #252a33;
    line-height: 1.75;
}

.sidebar-main {
    width: 296px;
    border-right: 1px;
    min-height: calc(100vh - 65px);
    border-right: 1px solid #d9dfea;
    background: #fff;
}

.sidebar-main::after {
    content: "";
    width: 296px;
    min-height: 100vh;
    border-right: 1px solid #d9dfea;
    background: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.sidebar-main ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: sticky;
    top: 65px;
}

.sidebar-main ul li button {
    padding: 0.25rem 1rem;
    margin: 0;
    width: 100%;
    text-decoration: none;
    font-size: 16px;
    color: #252a33;
    line-height: 1.75;
    border-radius: 0;
    background-color: #fff;
    border: 0;
}

.sidebar-main ul li button p {
    text-align: left;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
    margin: 0px;
    padding-right: 0px;
    padding-left: 0px;
    min-width: 0px;
}

.sidebar-main ul li button:hover {
    color: #252a33 !important;
    background-color: #f0f6ff !important;
}

.sidebar-main ul li button .sideIcon {
    padding: 0.5rem;
    display: flex;
}

.sidebar-main ul li button .icon {
    color: #aab7cb;
}

.sidebar-main ul li.active button .icon {
    color: rgb(41, 98, 255);
}

.sidebar-main ul li button:focus {
    box-shadow: 0 0px 0px 4px #dfedff;
    background-color: #fff !important;
    color: #252a33 !important;
    position: relative;
}

.sidebar-main ul li button .menu-count {
    margin-left: auto;
    padding: 0px 1rem;
    font-size: 12px;
    font-family: var(--Museo500);
    line-height: 20px;
    color: #65738b;
    background-color: #e5e9f1;
    border-radius: 2000px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-main ul li.active button {
    background-color: #f0f6ff !important;
    color: #2962ff !important;
}

.sidebar-main ul li.active button .menu-count {
    color: #f0f6ff;
    background-color: #2962ff;
}

.sidebar-main ul li button i {
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.25rem;
    font-size: 1.5rem;
}

.home-content {
    width: calc(100% - 296px);
    height: calc(100vh - 65px);
    background-color: #f7f8fa;
    padding: 1rem 13px;
}

.profile-container {
    width: 100%;
    max-width: 640px;
    margin: auto;
    border-radius: 8px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(37, 42, 51, 0.1), 0 1px 3px 0 rgba(37, 42, 51, 0.22);
    overflow: hidden;
    margin-bottom: 16px;
}

.profile-head {
    background-color: #08197a;
    padding: 40px 24px 24px;
    border-radius: 8px 8px 0 0;
}

.profile-img {
    width: 56px;
    height: 56px;
    margin-right: 12px;
}

.profile-img img,
.posts-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.profile-head h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #fff;
    line-height: 1.5;
    font-family: var(--Museo700);
}

.profile-head p {
    font-size: 0.875rem;
    line-height: 1.42857;
    margin: 0px;
    color: #fff;
    font-family: var(--Museo300);
}

.profile-description {
    padding: 24px 24px 24px;
}

.profile-description p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75;
    color: #252a33;
    margin-bottom: 0;
}

.posts-main {
    width: 100%;
    max-width: 640px;
    margin: auto;
}

.posts-main .posts {
    border-radius: 8px;
    background-color: #fff;
    padding: 16px;
    box-shadow: 0 2px 3px 0 rgba(37, 42, 51, 0.1), 0 1px 3px 0 rgba(37, 42, 51, 0.22);
    overflow: hidden;
    margin-bottom: 16px;
}

.posts-profile-head {
    margin-bottom: 16px;
}

.posts-profile-img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.posts-profile-img img {
    border: 0;
}

.posts-profile-name h2 {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--Avenir);
    color: #252a33;
}

.posts-profile-name p {
    color: #65738b;
    line-height: 1.6;
    font-size: 10px;
    margin: 0;
    font-family: var(--Museo700);
}

.post-content {
    margin-top: 16px;
}

.post-content h3 {
    font-size: 20px;
    font-family: var(--Museo700);
    line-height: 1.6;
    color: #252a33;
    margin: 0;
}

.post-content p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75;
    color: #252a33;
    margin-bottom: 0;
}

.single-img-container .image-container-inner {
    padding: 31.745% 25%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.cover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.multiple-imgs {
    margin: 0 -4px;
}

.multiple-imgs .image-container {
    padding: 0 4px;
    max-width: 214px;
    width: 100%;
}

.multiple-imgs.slider-main .image-container {
    max-width: 100%;
    display: flex !important;
}

.slider-main .slick-list {
    overflow: visible;
}

.image-container-inner {
    width: 100%;
    position: relative;
    padding: 31.25% 25%;
    overflow: hidden;
    border-radius: 4px;
}

.image-container .image-index {
    position: absolute;
    border-radius: 2000px;
    top: 8px;
    left: 8px;
    width: 32px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #2962ff;
    font-family: var(--Museo700);
    background-color: #fff;
    z-index: 1;
    line-height: 1;
}

.view-more-link a {
    line-height: 1.75;
    font-size: 16px;
    color: #2962ff;
    text-decoration: none;
    font-family: var(--Museo500);
    padding: 0;
    border-radius: 0;
    background: transparent;
    border: 0;
}

.view-more-link button:hover,
.view-more-link button:active,
.view-more-link button:focus {
    background: transparent !important;
    color: #2962ff !important;
}

.more-details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f6ff url(../images/Media_Thumbnail.png) no-repeat center center / cover;
    border-radius: 4px;
}

.more-details a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.more-details span {
    min-width: 84px;
    height: 32px;
    margin: 49px 62px;
    padding: 6px 10px 6px 10.5px;
    font-size: 12px;
    font-family: var(--Museo500);
    line-height: 1.67;
    border-radius: 2000px;
    background-color: #2962ff;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.more-details span i {
    font-size: 14px;
    margin-right: 7.5px;
}

.slider-main .slick-prev {
    left: 0;
}

.slider-main .slick-next {
    right: 0;
}

.slider-main .slick-prev,
.slider-main .slick-next {
    width: 40px;
    height: 40px;
    border-radius: 2000px;
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(37, 42, 51, 0.1), 0 1px 3px 0 rgba(37, 42, 51, 0.22);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-main .slick-prev:hover,
.slider-main .slick-next:hover {
    background-color: #fff;
    box-shadow: 0 9px 20px 0 rgba(37, 42, 51, 0.18), 0 3px 7px 0 rgba(37, 42, 51, 0.28);
}

.slider-main .slick-prev:focus,
.slider-main .slick-next:focus {
    background-color: #e8e8e9;
}

.slider-main .slick-disabled {
    display: none !important;
}

.slider-main .slick-prev:before,
.slider-main .slick-next:before {
    font-family: "NF_iconset";
    color: #485163;
    width: 100%;
    display: flex;
    justify-content: center;
}

.slider-main .slick-prev:before {
    content: "\ea49";
}

.slider-main .slick-next:before {
    content: "\ea47";
}

.detailed-post-content {
    width: 66.667%;
    flex-grow: 1;
    background-color: #252a33;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detailed-close-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 9;
}

.detailed-post-sidebar {
    width: 33.333%;
    background-color: #fff;
}

.detailed-post-main {
    min-height: 100vh;
}

.detailed-close-icon a {
    width: 40px;
    height: 40px;
    border-radius: 2000px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
    color: #485163;
    border: 0;
}

.post-sidebar-head {
    padding: 16px;
    border-bottom: 1px solid #e5e9f1;
}

.post-sidebar-head a,
.post-sidebar-head button,
.thumbnail-slider-wrap button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e5e9f1;
    text-decoration: none;
    color: #485163;
}

.post-sidebar-head button[disabled],
.thumbnail-slider-wrap button[disabled] {
    background-color: rgb(246, 249, 252);
    border-color: rgb(246, 249, 252);
    cursor: not-allowed;
    color: rgb(170, 183, 203);
}

.post-sidebar-head h3 {
    color: #252a33;
    font-size: 14px;
    font-family: var(--Museo500);
    margin: 0;
    width: calc(100% - 64px);
    text-align: center;
}

.post-sidebar-content {
    padding: 11px 16px;
    height: calc(100vh - 65px);
    overflow-y: auto;
    overflow-x: hidden;
}

.prev-next-btn {
    width: 32px;
}

.blankImg,
.no-images {
    max-width: 400px;
}

.no-work {
    text-align: center;
    margin: 94px 0;
}

.no-work h4 {
    color: #252a33;
    font-size: 24px;
    font-family: var(--Museo700);
    line-height: 1.5;
    margin: 24px 0 8px;
}

.no-work p {
    color: #65738b;
    line-height: 1.75;
    font-size: 16px;
    max-width: 456px;
    margin: 0 auto;
}

.audio-player {
    border-radius: 4px;
    border: solid 1px #e5e9f1;
    background-color: #f7f8fa;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25px;
}

.audio-player>div {
    width: 100%;
}

.time-play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    z-index: 9;
    text-align: center;
}

.play-button {
    width: 56px;
    height: 56px;
    z-index: 9;
    border-radius: 50%;
    background-color: #2962ff;
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto 0;
}

.play-button:hover {
    background-color: #5481ff;
}

.play-button:focus {
    background-color: #295cea;
    box-shadow: 0 0 0 3px #dfedff;
}

.timeline-main {
    position: absolute;
    bottom: 22px;
    left: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeline {
    height: 4px;
    flex-grow: 1;
    background-color: #d9dfea;
    position: relative;
}

.fullscreen-btn {
    margin-left: 15px;
}

.volume-btn button,
.fullscreen-btn button {
    background: transparent;
    padding: 0;
    display: grid;
    border: 0;
    color: rgb(101, 115, 139);
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.25rem;
    font-size: 1.5rem;
}

.timeline-progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #2962ff;
    height: 4px;
    margin: auto;
}

.timeline-progress::after {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 2000px;
    box-shadow: 0 2px 3px 0 rgba(37, 42, 51, 0.1), 0 1px 3px 0 rgba(37, 42, 51, 0.22);
    position: absolute;
    top: 0;
    right: -10px;
    background: #fff;
    bottom: 0;
    margin: auto;
}

.current-time {
    font-size: 12px;
    font-family: var(--Museo500);
    line-height: 1.67;
    color: #65738b;
    margin-top: 12px;
    min-width: 150px;
}

.multiple-imgs .play-button {
    width: 32px;
    height: 32px;
}

.multiple-imgs .timeline-main {
    bottom: 5px;
    left: 15px;
    right: 10px;
}

.multiple-imgs .volume-btn button,
.multiple-imgs .fullscreen-btn button {
    width: 1rem;
    height: 1rem;
    margin: 0.125rem;
    font-size: 1rem;
}

.multiple-imgs .fullscreen-btn {
    margin-left: 6px;
}

.multiple-imgs .volume-btn .volume-range {
    transform: translate(-38%, -100%) rotate(90deg);
}

.image-container-inner.pdf-view {
    padding: 0;
}

.image-container-inner.pdf-view iframe {
    width: 100%;
    height: 100%;
}

.single-img-container .video-js,
.multiple-imgs .video-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
}

.multiple-imgs .video-js {
    padding: 0 !important;
}

.detailed-post-main .detailed-post-content .video-js {
    height: calc(100vh - 180px) !important;
    padding: 0;
    padding: unset !important;
}

body .video-js .vjs-big-play-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0;
    line-height: 1;
    margin: 0;
    transform: translate(-50%, -50%);
}
body .detailed-post-content .video-js .vjs-big-play-button{
    width: 56px;
    height: 56px;
    align-items: center;
    justify-content: center;
}
body .detailed-post-content .video-js .vjs-big-play-button span::before {
    display: flex;
    align-items: center;
    justify-content: center;
}
.unknown-media {
    color: #fff;
}

.no-preview-img .contain-img {
    height: 100%;
    object-fit: contain;
}

.detailed-post-main .slider-wrap-main .image-container-inner,
.single-mediaItem .image-container-inner {
    padding: 0;
    position: unset;
    border-radius: 0;
}

.detailed-post-main .slider-wrap-main .image-container-inner {
    min-height: 100vh;
    padding: 90px 40px 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.detailed-post-main .slider-wrap-main .image-container-inner>div[data-vjs-player="true"] {
    width: 100%;
}

.detailed-post-main .slider-wrap-main .image-container-inner>div[data-vjs-player="true"]>div {
    width: 100%;
    max-height: calc(100vh - 180px);
}

.detailed-post-main .slider-wrap-main .image-container-inner>div {
    width: 100%;
}

.single-mediaItem .image-container-inner .video-container,
.detailed-post-main .slider-wrap-main .image-container-inner .video-container {
    position: relative;
    min-height: 480px;
}

.detailed-post-content .link-box {
    position: unset;
    object-fit: contain;
    width: auto;
    height: 100%;
    margin: auto;
    max-height: calc(100vh - 180px);
    border-radius: 0;
    border: 0;
}

.detailed-post-content .onlyImg {
    position: relative;
    width: 100%;
    height: calc(100vh - 180px);
}

.detailed-post-content .onlyImg img {
    object-fit: contain;
    border-radius: 0;
}

.single-mediaItem .cover-img.audio-player,
.detailed-post-content .link-box-content {
    width: 100%;
    height: calc(100vh - 180px);
}

.slider-wrap-main .slick-track {
    display: flex;
    align-items: center;
}

.detailed-post-main .detailed-post-content .image-container .image-index {
    top: 22px;
    right: 22px;
    left: unset;
    width: 45px;
    height: 24px;
    background-color: #e5e9f1;
    color: #65738b;
    font-size: 12px;
}

.slider-wrapper {
    width: 100%;
}

.thumbnail-slider-wrap {
    position: absolute;
    bottom: 25px;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    gap: 20px;
    padding: 0 15px;
}

.thumbnail-slider-wrap .image-container .image-index {
    display: none;
}

.thumbnail-slider-wrap .slick-slide .slick-slide {
    padding: 0 8px;
}

.thumbnail-slider-wrap .image-container-inner {
    border-radius: 4px;
    background-color: #fff;
    width: 35px;
    height: 35px;
    margin: 5px 0;
}

.thumbnail-slider-wrap .nav {
    gap: 15px;
    justify-content: center;
}

.thumbnail-slider-wrap .active {
    transform: scale(1.4);
}

.thumbnail-slider-wrap .image-container-inner .icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2962ff;
    font-size: 16px;
}

.single-mediaItem {
    width: 100%;
    text-align: center;
}

.detailed-post-main .detailed-post-content .image-container-inner .audio-player {
    height: 480px;
    border-radius: 0;

}

.audio-player-main {
    width: 100%;
    position: relative;
}

.sidebar-template-name {
    padding: 12px;
    border-bottom: 1px solid #d9dfea;
}

.sidebar-template-name>a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 2000px;
    background-color: #e5e9f1;
    text-decoration: none;
    font-size: 14px;
    margin-right: 15px;
    color: #485163;
}

.sidebar-template-name h4 {
    font-size: 12px;
    line-height: 1.67;
    color: #252a33;
    margin: 0;
    font-family: var(--Museo500);
}

.link-box {
    border-radius: 4px;
    border: solid 1px #e5e9f1;
    background-color: #f7f8fa;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}

.link-box-content {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.no-preview-img {
    max-width: 100%;
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
    height: calc(100% - 50px);
}

.slick-slide.slick-active.slick-current {
    z-index: 9;
}

.file-name {
    background-color: #fff;
    border-top: solid 1px #e5e9f1;
    padding: 16px;
    width: 100%;
    text-align: left;
}

.file-name p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #65738b;
    font-family: var(--Museo500);
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: flow-root;
}

.file-name a {
    display: flex;
    color: #2962ff;
    font-size: 16px;
    line-height: 1.5;
    font-family: var(--Museo500);
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: flow-root;
}

.multiple-imgs .file-name {
    padding: 6px;
}

.multiple-imgs .file-name p {
    font-size: 10px;
    line-height: 1.6;
    font-family: var(--Museo700);
}

.multiple-imgs .file-name a {
    line-height: 1.33;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: flow-root;
}

.link-mok-main {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: auto;
    max-height: calc(100vh - 180px);
    display: flex;
}

.loading-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: #fff;
}

.fullscreen-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-decoration: none;
    color: #fff;
    display: none;
    z-index: 9;
    cursor: pointer;
}

.fullscreen-icon i {
    border-radius: 50%;
    margin: auto;
    width: 32px;
    height: 32px;
    background: rgba(37, 42, 51, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container-inner:hover .fullscreen-icon {
    display: flex;
}

.detailed-post-content .audioPlayer-main {
    position: relative;
    height: 480px;
}

.detailed-post-content .file-name {
    border-bottom: solid 1px #e5e9f1;
    border-top: 0;
}

.detailed-post-content .document-box .file-name {
    display: none;
}

.detailed-post-content .file-name.link-icon-left {
    padding-left: 70px;
    position: relative;
}

.detailed-post-content .file-name.link-icon-left::before {
    content: "\ea4d";
    font: normal normal normal 1em/1 "NF_iconset";
    left: 16px;
    width: 40px;
    height: 40px;
    background-color: #f0f6ff;
    position: absolute;
    left: 16px;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2962ff;
    font-size: 24px;
}

.detailed-post-content .no-preview-img {
    order: 2;
    height: calc(100% - 81px);
    background-color: #f7f8fa;
}

.no-preview-img img {
    border-radius: 0;
}

.detailed-post-main .no-preview-img .document-box iframe {
    min-height: calc(100vh - 180px);
}

.no-preview-img iframe {
    height: 100%;
    width: 100%;
    background-color: #000;
}

.skeleton-post .posts-profile-img span {
    width: 32px;
    height: 32px;
    display: flex;
}

.skeleton-post span {
    display: flex;
}

.skeleton-post .image-container-inner span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.skeleton-post br {
    width: 100%;
    display: none;
}

/* Your CSS file (e.g., styles.css) */

.portal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure the portal is on top of other content */
}

.portal-content {
    background-color: #fff;
    position: relative;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
}

.close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.close-button i {
    font-size: 20px;
    color: #333;
}

/* Rich Text Description Limit */
/* .truncate-two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
} */
/* .truncate-two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.truncate-two-lines {
    height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.media-processing {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    flex-direction: column;
    border: solid 1px #e5e9f1;
    background-color: #f7f8fa;
    border-radius: 4px;
}

.defult-img {
    max-width: 300px;
}

.media-processing img {
    max-width: 200px;
}

.multiple-imgs .media-processing img {
    max-width: 80px;
}

.media-processing h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    color: #485163;
    font-family: var(--Museo500);
    margin-bottom: 0;
}

.media-processing p {
    line-height: 1.75;
    color: #65738b;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 300;
}

.multiple-imgs .media-processing h3 {
    font-size: 10px;
    line-height: 1.6;
}

.multiple-imgs .media-processing p {
    font-size: 10px;
    line-height: 1.6;
}

.detailed-post-content .media-processing {
    max-height: 480px;
    margin: auto;
    border-radius: 0;
}


.vjs-fullscreen-button {
    background-color: red !important;
}

.home-content .fullscreen-video {
    position: absolute;
    right: 12px;
    bottom: 7px;
    color: #fff;
    display: flex;
    text-decoration: none;
    cursor: pointer;
}

.home-content .vjs-fullscreen-control {
    display: none !important;
}

.home-content .video-js .vjs-control-bar {
    padding-right: 30px;
}

.volume-btn {
    position: relative;
    margin-left: 15px;
}

.volume-btn .volume-range {
    position: absolute;
    transform: translate(-30%, -100%) rotate(90deg);
    left: 0;
    top: -30px;
    background: #fff;
    box-shadow: 0 9px 20px 0 rgba(37, 42, 51, 0.18), 0 3px 7px 0 rgba(37, 42, 51, 0.28);
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    padding: 10px;
    opacity: 0;
    z-index: 9;
}

.volume-btn .volume-range input {
    height: 4px;
    width: 60px;
}

.volume-btn:hover .volume-range {
    opacity: 1;
}